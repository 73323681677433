import React from "react"
import { css } from "styled-components"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const wrapperStyles = css`
  padding: 0 15px;
  max-width: 600px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: center;

  h1 {
    font-size: 1.5em;
    margin-top: 50px;
    font-weight: 600;
    color: rgba(89, 89, 89, 0.7);
  }
`

const Success = () => {
  return (
    <Layout>
      <SEO title="Payment Successful" />

      <section css={wrapperStyles}>
        <h1>Thanks!</h1>
      </section>
    </Layout>
  )
}

export default Success
